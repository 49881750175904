import React from 'react'
import {Link} from 'gatsby'
import {OutboundLink} from "gatsby-plugin-google-analytics"

import instagram from '../img/social/instagram.svg'

const Footer = class extends React.Component {
    onGADiactivateClick = () => {
        window.gaOptout && window.gaOptout();
    }

    render() {
        return (
            <footer className="footer has-background-black has-text-white-ter">
                <div className="content has-text-centered">
                    <span style={{width: '14em', height: '10em'}}>Записки мамы</span>
                </div>
                <div className="content has-text-centered has-background-black has-text-white-ter">
                    <div className="container has-background-black has-text-white-ter">
                        <div style={{maxWidth: '100vw'}} className="columns">
                            <div className="column is-4">
                                <section className="menu">
                                    <div className="menu-list">
                                        <Link className="navbar-item" to="/">
                                            Домой
                                        </Link>
                                        <Link className="navbar-item" to="/blog/">
                                            Блог
                                        </Link>
                                        <Link className="navbar-item" to="/about/">
                                            Обо мне
                                        </Link>
                                    </div>
                                </section>
                            </div>
                            <div className="column is-4">
                                <section>
                                    <div className="menu-list">
                                        <Link className="navbar-item" to="/cities/">
                                            Города
                                        </Link>
                                        <Link className="navbar-item" to="/places/">
                                            Интересные места
                                        </Link>
                                        <Link className="navbar-item" to="/bookcase/">
                                            Книжный шкаф
                                        </Link>
                                        <Link className="navbar-item" to="/parenting/">
                                            Записки о материнстве
                                        </Link>
                                        <Link className="navbar-item" to="/life-in-nl/">
                                            Жизнь в NL
                                        </Link>
                                    </div>
                                </section>
                            </div>
                            <div className="column is-4 social">
                                <OutboundLink title={instagram}
                                              href="https://www.instagram.com/zapiskimami_iz_amsterdama">
                                    <img
                                        src={instagram}
                                        alt="Instagram"
                                        style={{width: '1em', height: '1em'}}
                                    />
                                </OutboundLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content has-text-centered">
                    <OutboundLink href="https://www.oleksiipopov.com">
                        Developed by Oleksii Popov
                    </OutboundLink>
                </div>
                <div className="content has-text-centered">
                    <a onClick={this.onGADiactivateClick}>Deactivate Google Analytics</a>
                </div>
            </footer>
        )
    }
}

export default Footer;
