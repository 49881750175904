import React from 'react';
import {Helmet} from 'react-helmet';

import favicon_svg from "./../img/favicon/favicon-optimized.svg";
import favicon_16 from "./../img/favicon/favicon-optimized-16.png";
import favicon_24 from "./../img/favicon/favicon-optimized-24.png";
import favicon_32 from "./../img/favicon/favicon-optimized-32.png";
import favicon_180 from "./../img/favicon/favicon-optimized-180.png";

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import useSiteMetadata from './SiteMetadata';
import './all.scss';

const Layout = (props) => {
    const {title, description} = useSiteMetadata();
    const metaDescription = props.description || description || "";
    const metaTitle = props.title || title || "";
    const metaKeyWords= props.keyWords;
    const metaOGImage= props.ogImage;

    return (
        <>
            <Helmet>
                <html lang="en" prefix="og: https://ogp.me/ns#"/>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription}/>
                {metaKeyWords && <meta name="keywords" content={metaKeyWords}/>}

                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={favicon_180}
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={favicon_32}
                    sizes="32x32"
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={favicon_24}
                    sizes="24x24"
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={favicon_16}
                    sizes="16x16"
                />

                <link
                    rel="mask-icon"
                    href={favicon_svg}
                    color="#ff4400"
                />
                <meta name="theme-color" content="#fff"/>

                <meta property="og:type" content="website"/>
                <meta property="og:title" content={metaTitle}/>
                <meta property="og:description" content={metaDescription}/>
                <meta property="og:site_name" content={title}/>
                {metaOGImage && <meta property="og:image" content={metaOGImage}/>}

            </Helmet>
            <Navbar/>
            <div className="main-container">{props.children}</div>
            <Footer/>
        </>
    )
}

export default Layout;
