import React from 'react';
import {Link} from 'gatsby';

import logo from '../img/logo/logo-optimized.svg';

const navBarActiveClass = 'is-active';

export default class Navbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            navBarActiveClass: ''
        }
    }

    toggleHamburger = () => {
        // toggle the active boolean in the state
        this.setState({
            active: !this.state.active
        });
    }

    render() {
        return (
            <nav
                className="navbar is-transparent"
                role="navigation"
                aria-label="main-navigation"
            >
                <div className="container">
                    <div className="navbar-brand">
                        <Link to="/" className="navbar-item" title="Logo">
                            <img src={logo} alt="Zapiski mami"/>
                        </Link>
                        <div
                            className={`navbar-burger burger ${this.state.active ? navBarActiveClass : ""}`}
                            data-target="navMenu"
                            onClick={this.toggleHamburger}
                        >
                            <span/>
                            <span/>
                            <span/>
                        </div>
                    </div>
                    <div
                        id="navMenu"
                        className={`navbar-menu ${this.state.active && navBarActiveClass}`}
                    >
                        <div className="navbar-start has-text-centered">
                            <Link className="navbar-item" to="/blog/">
                                Блог
                            </Link>
                            <Link className="navbar-item" to="/cities/">
                                Города
                            </Link>
                            <Link className="navbar-item" to="/places/">
                                Интересные места
                            </Link>
                            <Link className="navbar-item" to="/bookcase/">
                                Книжный шкаф
                            </Link>
                            <Link className="navbar-item" to="/parenting/">
                                Записки о материнстве
                            </Link>
                            <Link className="navbar-item" to="/life-in-nl/">
                                Жизнь в NL
                            </Link>
                            <Link className="navbar-item" to="/about/">
                                Обо мне
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}
